<template>
    <el-row>
        <div class="main-width-80">

            <el-container>
                <el-main style="padding-left:0;">
                    <h2 class="page-main-title">{{ pageContent.title }}</h2>
                    <p class="custom-html" v-html="pageContent.htmlContent" v-if="pageContent.htmlContent"></p>
                    <el-empty description="not available" v-if="!pageContent.htmlContent &&(pageContent.children &&pageContent.children.length==0) &&(pageList&&pageList.length==0)"></el-empty>
                    <morePage :children="pageContent.children"></morePage>
                    <ul class="new-list" v-if="pageList">
                        <li v-for="(item,index) in pageList" :key="index">
                            <p class="date">{{item.time}}</p>
                            <router-link :to="`/page/details?pageId=${item.id}`">{{item.title}}</router-link>
                            <p class="new-content"> <strong>{{item.time}} –</strong>{{item.summary}}</p>
                            <el-divider></el-divider>
                        </li>
    
                    </ul>
                    <el-pagination v-if="total>0" background layout="prev, pager, next" :current-page="params.pageNum" :page-size="params.pageSize" :total="total" @current-change="pageChildren">
                    </el-pagination>
                </el-main>
                <el-aside width="250px">
                    <ul class="aside-menu-list">
                        <li v-for="(navItem, indexs) in pageContent.neighbours" :key="indexs">
                            <!-- <a :href="`pages/details?pageId=${navItem.id}`"> {{ navItem.title }}</a> -->
                            <router-link :class="{'is-active':pageId==navItem.id}" :to="`/page/details?pageId=${navItem.id}`">{{ navItem.title }}
                            </router-link>
                        </li>
                    </ul>
    
                    <!-- <ul class="aside-menu-list" style="margin-bottom: 0">
                            <li class="aside-menu-title">标题</li>
                            <li>
                                <a href=""><i class="menu-icon"></i> Sending within Singapore</a>
                            </li>
                            <li>
                                <a href=""> <i class="menu-icon"></i> within Singapore</a>
                            </li>
                            <li>
                                <a href=""> <i class="menu-icon"></i> within Singapore</a>
                            </li>
                        </ul>
                        <ul class="aside-menu-list">
                            <li class="aside-menu-title">标题</li>
                            <li>
                                <a href=""><i class="menu-icon"></i> Sending within Singapore</a>
                            </li>
                            <li>
                                <a href=""> <i class="menu-icon"></i> within Singapore</a>
                            </li>
                            <li>
                                <a href=""> <i class="menu-icon"></i> within Singapore</a>
                            </li>
                        </ul> -->
                    <ul class="aside-menu-list aside-Guide">
                        <li class="aside-menu-title"></li>
                        <li class="aside-menu-li-img">
                            <img src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/3Asset%201%403x.png" alt="" />
                        </li>
                        <li>
                            <p>
                                A standard package is approximately the size of an A4 size book.
                            </p>
                        </li>
                        <li style="margin-top: 50px; margin-bottom: 50px">
                            <img src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/3Asset%203%403x.png" alt="" />
                        </li>
                        <li>
                            <p>
                                An oversized parcel is approximately the size of two A4 size books stacked together as illustrated above.
                            </p>
                        </li>
                        <li class="font-18">Weight Guide</li>
                        <li>
                            <p>
                                A standard bottled mineral water weighs about 500g or 0.5kg.
                            </p>
                        </li>
                    </ul>
                </el-aside>
            </el-container>
        </div>
    </el-row>
</template>

<script>
import { websitePage, pageChildren } from "../api/index.js";
import morePage from './more-services.vue'
export default {
    components: { morePage },
    data() {
        return {
            pageId: this.$route.query.pageId,
            pageContent: {},
            params: {
                pageId: this.$route.query.pageId,
                pageSize: 10,
                pageNum: 1
            },
            total: 0,
            pageList: [],
        };
    },
    mounted() {
        this.details();
        this.pageChildren()
        document.body.scrollTop = document.documentElement.scrollTop = 0

    },
    methods: {
        details() {
            websitePage({ pageId: this.pageId }).then((res) => {
                this.pageContent = res.data;
                this.setIds(res.data.path)
            });
        },
        setIds(data) {
            if (data) {
                ids = []
                let ids = data.map(item => {
                    return item.id
                })
                localStorage.setItem('ids',ids)
            }
        },
        pageChildren() {
            pageChildren(this.params).then(res => {
                this.pageList = res.data.data
                this.total = res.data.total
            })
        }
    },
    computed: {},
    watch: {
        pageId(val) {
            if (val) {
                console.log(val);
                this.details();
            }
        },
    },
};
</script>


<style lang="less" scoped>
/deep/ .custom-html {
    @import '../assets/less/table.less';
    a {
        text-decoration: none;
    }
    p {
        padding: 10px 0;
    }
}

.new-list {
    text-align: left;
    .date {
        font-size: 14px;
        color: #666;
        font-weight: 500;
        padding-bottom: 10px;
    }
    .new-content {
        padding-top: 10px;
    }
}

.aside-menu-list {
    padding: 15px 0;
    margin: 0 0 20px;
    border: 1px solid #c0d1dc;
    li {
        position: relative;
    }
    .aside-menu-title,
    a {
        padding: 5px 5px 5px 25px;
        display: block;
        position: relative;
        font-size: 16px;
        text-align: left;
        font-weight: 600;
    }
    .is-active::before {
        content: "";
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #c31127;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        a {
            color: #3982ea;
            &:hover {
                color: #2f5179;
            }
        }
        .menu-icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 1px solid red;
            margin-right: 10px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .aside-menu-li-img {
        padding-bottom: 40px;
    }
}

.aside-Guide {
    padding: 15px;
    .aside-menu-title {
        padding-left: 10px;
    }
    li {
        p {
            font-size: 16px;
            text-align: left;
            font-weight: 500;
            color: #333;
        }
    }
    .font-18 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 15px;
    }
}

.page-main-title {
    text-align: left;
    font-size: 28px;
    font-weight: 400;
    color: #24549d;
    margin-bottom: 20px;
}

/deep/ .custom-html {
    text-align: left;
    p {
        font-size: 16px;
    }
}
</style>
<template>
  <el-row>
  <ul class="shop-list">
        <li v-for="(item,index) in children" :key="index">
          <h2 @click="toDetail(item.id)">{{item.title}}</h2>
          <div class="img-div"  v-if="item.coverImgUrl">
            <img
              :src="item.coverImgUrl"
              alt=""
            />
          </div>
          <p v-if="item.summary">
           {{item.summary}}
          </p>
          <ul class="links-vertical-ul" v-if="item.children&&item.children.length>0">
            <li class="links-vertical" v-for="(i,j) in item.children" :key="j">
              <i class="icon-right"></i>
              <router-link :to="`/page/details?pageId=${i.id}`" href="">{{i.title}}</router-link>
            </li>
          </ul>
        </li>
      </ul>
  </el-row>
</template>

<script>
export default {
  props:{
    children:{
      type:Array
    }
  },
  data() {
    return {};
  },
  methods:{
    toDetail(id){
      this.$router.push({
        path:'/page/details',
        query:{
          pageId:id
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
 @import "../assets/less/common.less";

.shop-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    width: 32%;
    margin-right: 1%;
    margin-bottom: 25px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      font-weight: 500;
      margin-bottom: 15px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #24549d;
      cursor: pointer;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
    .img-div {
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}
.shop-list-title {
  text-align: left;
  margin-bottom: 50px;
  h1 {
    font-weight: 300;
    font-style: normal;
    color: #24549d;
    font-size: 30px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
  }
}
.links-vertical-ul {
  .links-vertical {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
    margin-bottom:0;
    a {
      color: #3982ea;
      font-size: 16px;
      font-weight: 500;
    }
    .icon-right {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: 3px solid #d6e1e7;
      border-right: 3px solid #d6e1e7;
      transform: rotate(45deg);
      border-radius: 1px;
      margin-right:15px;
    }
  }
}
</style>
